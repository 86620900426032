<template>
    <div class="portfolio-piece">
        <div class="scroll-header">
            <div class="inf-scroll">
                <div>
                    <h1 class="add-kerning"><span>Good Time </span>Charlie's</h1>
                    <h1 class="add-kerning"><span>Good Time </span>Charlie's</h1>
                </div>
            </div>
        </div>
        <h2>Brand Design | Brand Update | Collateral</h2>

        <div class="portfolio-cont">

            <div class="p-content-row-2">
                <div>
                    
                </div>
                <div>
                    <p>Good Time Charlie's is an Americano restaurant in San Antonio. The existing brand had become outdated and was in need of improvements and expansion. This involved a new logo, 
                        stationary sets and the menu. Additional touchpoints were added to solidify the new identity of GTC.</p>
                </div>
            </div>
        
            <div class="p-content-row-1">
                <figure class="large-img">
                    <img alt="good time charlies logo" src="../assets/images/portfolio/ch-logo.jpg">
                </figure>
            </div>

            <div class="p-content-row-2">
                <div>
                    <figure class="medium-img">
                        <img alt="charlies stationary set" src="../assets/images/portfolio/ch-stationary1-res.jpg">
                    </figure>
                </div>
                <div>
                    <figure class="medium-img">
                        <img alt="charlies placemat menu" src="../assets/images/portfolio/ch-stationary2-res.jpg">
                    </figure>
                </div>
            </div>

            <div class="p-content-row-2">
                <div>
                    <figure class="medium-img">
                        <img alt="charlies apron" src="../assets/images/portfolio/ch-apron-res.jpg">
                    </figure>
                </div>
                <div>
                    <figure class="medium-img">
                        <img alt="charlies logo on wooden wall" src="../assets/images/portfolio/ch-secondary-res.jpg">
                    </figure>
                </div>
            </div>

            <div class="p-content-row-2">
                <div>
                    <figure class="medium-img">
                        <img alt="charlies logo on glass" src="../assets/images/portfolio/ch-window-res.jpg">
                    </figure>
                </div>
                <div>
                    <figure class="medium-img">
                        <img alt="charlies checkbook" src="../assets/images/portfolio/ch-checkbook-res.jpg">
                    </figure>
                </div>
            </div>

        </div>
    </div>
</template>

<script>

export default {
    name: "PortfolioEtaerio",

}
</script>

<style lang="scss">

</style>